$ff_poppins: 'Poppins', sans-serif;

$primary_color: #fff;
$accent_color: #cacaca;

$background_primary: #000;
$background_secondary:  #0b0221;

$default_color: #2a1664;

/* $default_color: #673ce9; */
$secondary_color: #20abcc;

$news_color: #58bb00;
$media_color: #e37e20;
$podcast_color: #02bc9b;
$coupons-color: #02bc9b;
$radio_color: #e7c900;
$product_color: #e68800;

$error_color: #c22020;
$success_color: #179617;

$bg_default_color: #2a1664;
$bg_primary_color: #10062b;
$bg_secondary_color: #2a1664;

$bg_body: #2a1664;
$bg_sidenav: #1a0054;
$bg_sidebar: #04010a;
$bg_footer: #0b0221;

$bg_product_primary: #02000e;
$bg_product_secondary: #10062b;
$bg_product_default: #221253;

$bg_coupon: #4b11bd;
$bg_coupon_secondary: #8417ff;

$radio_player_color1: #e5c000;
$radio_player_color1_var: #f7b401;
$radio_player_color2: #693be4;
$radio_player_color2_var: #532eb1;
$radio_player_background: #060606;

$facebook_color: #324a81;
