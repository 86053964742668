@import './_variables.scss';

html {
  scroll-behavior: smooth;
}

html,
body {
  background: $background_primary;
  color: #fff;
	font-family: $ff_poppins;
  font-weight: 400;
  width: 100%;
  height: auto;
  min-height: 100vh;
  font-size: 1.12rem;
}

.v-aling {
  display: grid;
  place-items: center;
}

iframe {
  border-radius: 10px;
  overflow: hidden;
}

footer {
  padding: 10px;
  text-align: center;
  font-weight: 700;/*
  font-size: 0.9rem; */
  a {
    color: $primary_color;
    transition: 2s;
    display: inline-block;
    text-decoration: none;
    margin: 5px 10px;
    &:hover,
    &:focus {
      color: $accent_color;
    }
  }
}

