@import 'styles/_variables.scss';

.Home {
  width: 100%;
  min-width: 360px;
  overflow-x: hidden;

  .video-js {
    border-radius: 10px;
    overflow: hidden;
  }

  .page-section {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: $background_primary;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    place-items: center;
    position: relative;
    top: 0;
    left: 0;
    padding: 50px 15px 95px 15px;
    text-align: center;
    &:nth-child(even) {
      background-color: $background_secondary;

      .station-icon {
        background-color: rgba($background_primary, 1);
      }

      .service {
        background-color: rgba($background_primary,0);
      }
      .metrics,
      .metrics-radio {
        .box-metrics,
        .user-profile,
        .user-ages,
        .technologies,
        .followers {
          background-color: rgba($background_primary,0.5);
        }
      }

    }

    /* .fancy-background {
      background-color: rgba(0,0,0,0.7);
      border-radius: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
    } */

    .section-title {
      margin-bottom: 20px;
      font-weight: 700;
    }

    .section-subtitle {
      font-size: 1.3rem;
      margin-bottom: 16px;
      font-weight: 700;
    }

    .text {
      p {
        margin-bottom: 5px;
      }
      margin-bottom: 15px;
    }

    .station-icon {
      display: inline-block;
      width: 70px;
      height: 70px;
      margin: 10px;
      background-color: rgba($background_secondary, 1);
      padding: 10px;
      border-radius: 50%;
    }

    .service {
      background-color: rgba($background_secondary,0);
      padding: 10px 20px;
      border-radius: 10px;
    }

    .next-container {
      height: 80px;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 100%;
      .btn {
        width: 90px;
        border: 0 solid transparent;
        box-shadow: none;
        &:focus {
          outline: none !important;
        }
      }
    }
  }

  .customers-logos {
    figure {
      width: 200px;
      height: auto;
      display: block;
      margin: 10px auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .service-box {
    img {
      display: block;
      margin: 0 auto;
      width: 75px;
      height: auto;
    }
  }

  .customers-logos {
    position: relative;
    top: 0;
    left: 0;
    height: 135px;
    .col {
      position: absolute;
      left: 0;
      top: 0;
      background-color: #414141;
      padding: 0;
    }
    .swiper-slide {
      width: 220px;
    }
  }
}



.metrics,
.metrics-radio {
  .box-metrics {
    width: 200px;
    height: 200px;
    margin: 10px auto;
    padding: 5px 10px 15px 10px;
    background-color: rgba($background_primary, 0.5);
    border-radius: 10px;
    svg {
      width: 100px;
      height: 100px;
      display: block;
      margin: 0 auto;
    }
    figcaption {
      padding-bottom: 5px;
      height: 75px;
    }
    .number,
    .title {
      margin-bottom: 0px;
    }
    .number {
      font-size: 1.2rem;
      font-weight: 700;
    }
    .title {
      font-size: 0.75rem;
    }
  }
  .user-profile,
  .user-ages,
  .technologies,
  .followers {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 310px;
    margin: 15px auto;
    background-color: rgba($background_primary,0.5);
    border-radius: 15px;
    padding: 10px;
    .title {
      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 5px;
    }
  }
  .user-ages {
    .age-row {
      position: relative;
      top: 0;
      left: 0;
      padding-left: 52px;
      height: 26px;
      line-height: 1;

      .label {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 0.9rem;
      }

      .value {
        width: calc(100% - 55px);
        text-align: center;
        line-height: 20px;
        position: absolute;
        left: 55px;
        top: 0;
        font-size: .9em;
      }

      .progress {
        background-color: rgba($default_color, 0.5);
        .progress-bar {
          background-color: $default_color;
          font-size: 0.65rem;
        }
      }

    }
  }

  .user-profile {
    figure {
      width: 140px;
      position: relative;
      left: 0;
      top: 0;
      padding: 15px 50px 15px 15px;
      border-radius: 15px;
      display: inline-block;
      margin: 5px 5px 5px 0;
      img,
      svg {
        width: 75px;
        height: 75px;
      }
      figcaption {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        .value {
          margin-bottom: 0;
          font-size: 20px;
          font-weight: 700;
          line-height: 105px;
        }
      }
    }
  }

  .technologies {
    width: 380px;
    figure {
      display: inline-block;
      width: 100px;
      margin: 10px;
    }
    img,
    svg {
      width: 100px;
      height: 100px;
    }
    figcaption {
      padding-top: 5px;
      .value,
      .title {
        margin-bottom: 0px;
      }
      .value {
        font-size: 1.2rem;
        font-weight: 700;
      }
      .title {
        font-size: 0.8rem;
      }
    }
  }

  .followers {
    width: 300px;
    figure {
      display: inline-block;
      width: 100px;
      margin: 10px;
    }
    img,
    svg {
      width: 60px;
      height: 60px;
      margin: 20px auto;
    }
    figcaption {
      padding-top: 5px;
      .value,
      .title {
        margin-bottom: 0px;
      }
      .value {
        font-size: 1.2rem;
        font-weight: 700;
      }
      .title {
        font-size: 0.8rem;
      }
    }
  }
}

@media(max-width: 440px) {
  .metrics,
  .metrics-radio {
    .technologies {
      width: 250px;
      figure {
        display: block;
        margin: 10px auto;
      }
      figcaption {
        padding-top: 5px;
        .value,
        .title {
          margin-bottom: 0px;
        }
        .value {
          font-size: 1.2rem;
          font-weight: 700;
        }
        .title {
          font-size: 0.8rem;
        }
      }
    }
  }
}

#welcome {
  background-image: url('../../../images/backgrounds/1welcome-bg.jpg');
}

#planning {
  background-image: url('../../../images/backgrounds/2planning-bg.jpg');
}

#strategies {
  background-image: url('../../../images/backgrounds/3strategies-bg.jpg');
}

#social-media {
  background-image: url('../../../images/backgrounds/4socialmedia-bg.jpg');
}

#radio,
#metrics-radio {
  background-image: url('../../../images/backgrounds/5radio-bg.jpg');
}

#seo {
  background-image: url('../../../images/backgrounds/6seo-bg.jpg');
}

#services {
  background-image: url('../../../images/backgrounds/7services-bg.jpg');
}

#metrics {
  background-image: url('../../../images/backgrounds/8metrics-bg.jpg');
}

#portfolio {
  background-color: rgba($background_secondary, 1);
}
/*
#portfolio {
  background-image: url('../../../images/backgrounds/9portfolio-bg.jpg');
}
*/


@media(max-width: 768px) {
  .Home {
    .page-section {
      background-attachment: unset;
    }
  }
}
